import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "base-list",
    class: "base-list",
    tag: "ul"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (match) => {
        return (_openBlock(), _createBlock("li", {
          class: "base-list-each",
          key: match.id
        }, [
          _renderSlot(_ctx.$slots, "default", { item: match })
        ]))
      }), 128))
    ]),
    _: 3
  }))
}

  /**
   * BaseList component props.
   * @typedef {Object} BaseListProps
   * @property {Array} items List of items to loop over
   */
  export default {
    name: "base-list",
    /**
     * @type BaseListProps
     */
    props: {
      items: {
        type: Array,
        default: [],
      },
    },
  }
