/**
 * Transforms a section object into a search reference item.
 * @param {StyleGuideSection} section Section object
 * @returns {SearchReferenceItem} Search reference item
 */
export const transformSearchReferenceItem = (section) => {
  const { referenceURI, header } = section

  // FIXME: Might need to rethink how matches should work.
  // The problem is description isn't very reliable for matching terms.
  // Perhaps implement a tag system and use description as lowest sort?
  const description = section.description
    // get rid of <pre> and its contents
    .replace(/(?:\<pre[\S\s]*?\>)([\S\s]*?)(?:\<\/pre\>)/gi, "")
    // get rid of remaining HTML tags
    .replace(/(<([^>]+)>)/gi, "")
  // take only the first paragraph of text (non-HTML)
  // .replace(/(?:\\r\\n|[\\r\\n]){2}.*/, "")

  const rootSection = referenceURI.split("-")[0]
  const url = `section-${rootSection}.html#kssref-${referenceURI}`

  return {
    id: referenceURI,
    title: header,
    description,
    url,
  }
}

/**
 * Fetches style guide data from a URL.
 * @param {string} url URL to style guide datasource
 * @returns {Promise<StyleGuide>} Style guide data object
 * @throws Will throw an error when HTTP response code is not in the success range.
 */
export const fetchStyleguideData = async (url) => {
  const res = await fetch(url)

  if (!res.ok) throw Error(res.statusText)

  return res.json()
}

/**
 * Array.prototype.filter function factory that incorporates search term.
 * @param {string} term Search term
 * @returns {Function} Array.prototype.filter function incorporating `term`
 */
export const searchReferenceFilter = (term) => {
  /**
   * Array.prototype.filter function that checks if `item` matches `term`.
   * @param {SearchReferenceItem} item Search reference item
   * @returns {boolean} Whether `item` matches `term`
   */
  const filterCallback = (item) => {
    return (
      item.title.toLowerCase().includes(term) ||
      item.description.toLowerCase().includes(term)
    )
  }
  return filterCallback
}

/**
 * Array.prototype.sort function factory that incorporates search term.
 * @param {string} term Search term
 * @returns {Function} Array.prototype.sort compare function incorporating `term`
 */
export const searchReferenceSort = (term) => {
  /**
   * Array.prototype.sort compare function that contains the sorting logic.
   * @param {SearchReferenceItem} a First element for comparison
   * @param {SearchReferenceItem} b Second element for comparison
   * @returns {number} Value representing order of `a` and `b`
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description
   */
  const sortCompare = (a, b) => {
    if (a.title.toLowerCase() === term) return -1
    else if (b.title.toLowerCase() === term) return 1
    else if (a.title.toLowerCase().includes(term)) return -1
    else if (b.title.toLowerCase().includes(term)) return 1

    return 0
  }
  return sortCompare
}
