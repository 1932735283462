/**
 * KssGrid provides for the central control of grid display toggling throughout
 * the entire page.
 */

/**
 * KssGrid config.
 * @typedef {Object} KssGridConfig
 * @property {string} [bodyClass] CSS selector name of feature to toggle in <body>
 */

/**
 * Class KssGrid.
 */
class KssGrid {
  /**
   * Constructor.
   * @param {KssGridConfig} [config] KssGrid config
   */
  constructor(config) {
    this.bodyClass = config?.bodyClass || "kss-grid-mode"

    this.init()
  }

  /**
   * Initialize all grid toggle buttons which appear in sections that contains
   * examples.
   */
  init() {
    const elementList = document.querySelectorAll("a[data-kss-grid]")
    for (const button of elementList) {
      button.onclick = this.showGuides.bind(this)
    }
  }

  /**
   * Toggles the `bodyClass` in `<body>`.
   */
  showGuides() {
    document.body.classList.toggle(this.bodyClass)
  }
}

export default KssGrid
