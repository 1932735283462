
          import script, { render } from "./vue/components/TheSearchModal.vue?vue&type=script"
          import "./vue/components/TheSearchModal.vue?vue&type=style"

          script.render = render

          script.__file = "./vue/components/TheSearchModal.vue"
          script.__scopeId = "data-v-s7348l"

          export default script
        