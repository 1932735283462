
          import script, { render } from "./BaseList.vue?vue&type=script"
          import "./BaseList.vue?vue&type=style"

          script.render = render

          script.__file = "./BaseList.vue"
          script.__scopeId = "data-v-78x1rk"

          export default script
        