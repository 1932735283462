/**
 * LssScrollDirectionIndicator activates CSS classes on the <body> to indicate
 * when the user has scrolled up/down.
 * NOTE: When at the top of the screen, all added selectors are removed.
 */

/**
 * LssScrollDirectionIndicator config.
 * @typedef {Object} LssScrollDirectionIndicatorConfig
 * @property {number} [scrollDownThreshold] Scroll down velocity before activating
 * @property {number} [scrollUpThreshold] Scroll up velocity before activating
 * @property {string} [scrollDownClass] Scroll down class selector name to apply in <body>
 * @property {string} [scrollUpClass] Scroll up class selector name to apply in <body>
 */

/**
 * Class LssScrollDirectionIndicator.
 */
class LssScrollDirectionIndicator {
  /**
   * Constructor.
   * @param {LssScrollDirectionIndicatorConfig} config LssScrollDirectionIndicator config
   */
  constructor(config) {
    this.scrollDownThreshold = config?.scrollDownThreshold || 0
    this.scrollUpThreshold = config?.scrollUpThreshold || 30
    this.scrollDownClass = config?.scrollDownClass || "js_scroll-down"
    this.scrollUpClass = config?.scrollUpClass || "js_scroll-up"
    this.lastScrollPos = 0
    this.scrollHandler = this.scrollHandler.bind(this)

    this.start()
  }

  /**
   * Callback handler for adding/removing of CSS classes on scroll event.
   */
  scrollHandler() {
    const body = document.body
    const scrollPos = window.pageYOffset

    // At top of screen
    if (scrollPos <= 0) {
      body.classList.remove(this.scrollUpClass)
      body.classList.remove(this.scrollDownClass)
    }

    // Scrolling down
    if (scrollPos > this.lastScrollPos + this.scrollDownThreshold) {
      body.classList.add(this.scrollDownClass)
      body.classList.remove(this.scrollUpClass)
    }
    // Scrolling up
    else if (scrollPos < this.lastScrollPos - this.scrollUpThreshold) {
      body.classList.add(this.scrollUpClass)
      body.classList.remove(this.scrollDownClass)
    }

    this.lastScrollPos = scrollPos
  }

  /**
   * Sets scrollDownClass to `<body>`.
   */
  setScrollDown() {
    if (window.pageYOffset === 0) return
    document.body.classList.add(this.scrollDownClass)
    this.lastScrollPos = window.pageXOffset
  }

  /**
   * Starts listening to scroll event.
   */
  start() {
    document.removeEventListener("scroll", this.scrollHandler)
    document.addEventListener("scroll", this.scrollHandler)
  }

  /**
   * Stops listening to scroll event.
   */
  stop() {
    document.removeEventListener("scroll", this.scrollHandler)
  }
}

export default LssScrollDirectionIndicator
