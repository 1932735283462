/**
 * KssFullScreen provides for the toggling of fullscreen mode for each section.
 */

/**
 * KssFullScreen config.
 * @typedef {Object} KssFullScreenConfig
 * @property {string} [idPrefix] The string that uniquely prefixes the ID of all elements that can receive the fullscreen focus.
 * @property {string} [bodyClass] The class that is set on the body element when the fullscreen mode is toggled on.
 * @property {string} [elementClass] the class that is set on the element that is receiving the fullscreen focus.
 */

/**
 * Class KssFullScreen.
 */
class KssFullScreen {
  /**
   * Constructor.
   * @param {KssFullScreenConfig} [config] KssFullScreen config
   */
  constructor(config) {
    this.idPrefix = config?.idPrefix || "kss-fullscreen-"
    this.bodyClass = config?.bodyClass || "kss-fullscreen-mode"
    this.elementClass = config?.elementClass || "is-fullscreen"

    this.init()
  }

  /**
   * Initialize the page to see if the fullscreen mode should be immediately
   * turned on.
   */
  init() {
    // Check the location hash to see if it matches the idPrefix.
    if (
      window.location.hash.slice(0, this.idPrefix.length + 1) ===
      "#" + this.idPrefix
    ) {
      this.setFocus(window.location.hash.slice(1 + this.idPrefix.length))
    }

    // Initialize all fullscreen toggle buttons.
    const elementList = document.querySelectorAll("a[data-kss-fullscreen]")
    for (const button of elementList) {
      // Get the section reference from the data attribute.
      button.onclick = this.setFocus.bind(this, button.dataset.kssFullscreen)
    }
  }

  /**
   * Activation function that takes the ID of the element that will receive
   * fullscreen focus.
   * @param {string} id ID of element that will receive fullscreen focus
   */
  setFocus(id) {
    let el

    // Find the element with the given ID and start fullscreen mode.
    if ((el = document.getElementById(id))) {
      el.classList.toggle("is-fullscreen")
      document.body.classList.toggle("kss-fullscreen-mode")

      // When enabling the focus mode, change the location hash.
      if (el.classList.contains("is-fullscreen")) {
        window.location.hash = "#" + this.idPrefix + id
        // Don't follow the link location.
        return false
      }
    }

    return true
  }
}

export default KssFullScreen
