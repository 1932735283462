
          import script, { render } from "./SearchResults.vue?vue&type=script"
          import "./SearchResults.vue?vue&type=style"

          script.render = render

          script.__file = "./SearchResults.vue"
          script.__scopeId = "data-v-9hlgc6"

          export default script
        