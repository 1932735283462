/**
 * LssScrollSpy activates CSS class on the nav item whose hash matches the
 * `id` of the section in view.
 */

import section from "../../../extend/section"

/**
 * LssScrollSpy config.
 * @typedef {Object} LssScrollSpyConfig
 * @property {string} [selectorNavItem] Selector of nav items to apply CSS class
 * @property {string} [selectorSection] Selector section to spy on
 * @property {string} [activeClass] CSS classname to apply
 */

/**
 * Class LssScrollSpy.
 */
class LssScrollSpy {
  /**
   * Constructor.
   * @param {LssScrollSpyConfig} config LssScrollSpy config
   */
  constructor(config) {
    this.selectorNavItem = config?.selectorNavItem || ".kss-nav__menu-link"
    this.selectorSection = config?.selectorSection || ".kss-section"
    this.activeClass = config?.activeClass || "is-in-viewport"
    this.sections = []
    this.navItems = {}
    this.scrollHandler = this.scrollHandler.bind(this)
    this.setActive = this.setActive.bind(this)
    // hold a timer so that we know when scrolling has stopped
    this.timerScrolling = null

    this.init()
    this.start()
  }

  /**
   * Callback handler for adding/removing CSS classes on scroll.
   */
  scrollHandler() {
    const scrollPos = window.pageYOffset

    // iterate through all sections on page and find a matching section
    const intersectSectionId = this.sections.find((key) => {
      const sectionElem = document.getElementById(key)
      const top = sectionElem.offsetTop
      const bottom = sectionElem.offsetTop + sectionElem.offsetHeight

      return scrollPos >= top && scrollPos < bottom
    })

    // debounce the timer
    clearTimeout(this.timerScrolling)
    this.timerScrolling = setTimeout(() => {
      this.removeActive()
      this.setActive(intersectSectionId)
    }, 100)
  }

  /**
   * Performs initialization.
   */
  init() {
    // Gather sections
    const sectionNodes = Array.from(
      document.querySelectorAll(this.selectorSection)
    )

    this.sections = sectionNodes.map((node) => node.id)

    // Gather nav items
    const navItemNodes = Array.from(
      document.querySelectorAll(this.selectorNavItem)
    )
    this.navItems = navItemNodes.reduce((acc, curr) => {
      const hash = curr.href.split("#")[1] ?? ""
      if (hash) {
        acc[hash] = curr
      }
      return acc
    }, {})

    this.scrollHandler()
  }

  /**
   * Starts listening to scroll event.
   */
  start() {
    document.removeEventListener("scroll", this.scrollHandler)
    document.addEventListener("scroll", this.scrollHandler)
  }

  /**
   * Stops listening to scroll event.
   */
  stop() {
    this.removeActive()
    document.removeEventListener("scroll", this.scrollHandler)
  }

  /**
   * Removes any active class in the nav items.
   */
  removeActive() {
    const activeNavItem = document.querySelector("." + this.activeClass)
    if (activeNavItem) activeNavItem.classList.remove(this.activeClass)
  }

  /**
   * Sets active class on nav item whose hash matches the section ID.
   * @param {string} id ID of section
   */
  setActive(id) {
    if (this.navItems[id]) {
      this.navItems[id].classList.add(this.activeClass)

      const container = this.navItems[id].closest("ul.kss-nav__menu-child")
      if (
        // if scrolling container exists
        container !== undefined &&
        // scrolling container has overflowed
        container?.scrollHeight > container?.offsetHeight &&
        // active item is obscured
        (this.navItems[id].offsetTop >
          container?.scrollTop + container?.offsetHeight ||
          this.navItems[id].offsetTop < container?.scrollTop)
      ) {
        container.scroll({
          top: this.navItems[id].offsetTop - 16,
          behavior: "smooth",
        })
      }
    }
  }
}

export default LssScrollSpy
