/**
 * KssMarkup provides for the central control of markup display toggling
 * throughout the entire page.
 */

/**
 * KssMarkup config.
 * @typedef {Object} KssMarkupConfig
 * @property {string} [bodyClass] CSS selector name of feature to toggle in <body>
 * @property {string} [detailsClass] CSS selector name of markup wrapper <details>
 */

/**
 * Class KssMarkup.
 */
class KssMarkup {
  /**
   * Constructor.
   * @param {KssMarkupConfig} [config] KssMarkup config
   */
  constructor(config) {
    this.bodyClass = config?.bodyClass || "kss-markup-mode"
    this.detailsClass = config?.detailsClass || "kss-markup"

    this.init()
  }

  /**
   * Initialize all markup toggle buttons which appear in sections that contains
   * examples (and hence, markup).
   */
  init() {
    const elementList = document.querySelectorAll("a[data-kss-markup]")
    for (const button of elementList) {
      button.onclick = this.showGuides.bind(this)
    }
  }

  /**
   * Finds all markup wrappers and toggles their states.
   */
  showGuides() {
    const body = document.getElementsByTagName("body")[0],
      enabled = body.classList.contains(this.bodyClass)

    const elementList = document.querySelectorAll("." + this.detailsClass)
    for (const el of elementList) {
      if (enabled) {
        el.removeAttribute("open")
      } else {
        el.setAttribute("open", "")
      }
    }

    // Toggle the markup mode.
    body.classList.toggle(this.bodyClass)
  }
}

export default KssMarkup
