
          import script, { render } from "./Search.vue?vue&type=script"
          import "./Search.vue?vue&type=style"

          script.render = render

          script.__file = "./Search.vue"
          script.__scopeId = "data-v-6d9yie"

          export default script
        