/**
 * KssStateGenerator finds all the CSS rules on the page that contains pseudo
 * selectors (as defined in `pseudo_selectors`) and generates a `<style>` for each
 * matching rule that will contain the same rule but with the pseudo selector
 * string replaced with a `.pseudo-class-*` selector.
 *
 * For example, a selector like `.btn:hover` will generate a `<style>` that
 * contains the same rule but with a selector of `.btn.pseudo-class-hover`.
 */

/**
 * Class KssStateGenerator.
 */
class KssStateGenerator {
  /**
   * Constructor.
   * @param {string[]} [_whitelist=[]] List of whitelisted CSS filenames, check is ignored if list is empty
   * @param {string[]} [_pseudoSelectors] List of pseudo selector names to match
   */
  constructor(_whitelist = [], _pseudoSelectors) {
    this.whitelist = _whitelist
    this.pseudo_selectors = _pseudoSelectors || [
      "hover",
      "enabled",
      "disabled",
      "active",
      "visited",
      "focus",
      "target",
      "checked",
      "empty",
      "first-of-type",
      "last-of-type",
      "first-child",
      "last-child",
    ]
    this.rules = []

    this.generate()
  }

  /**
   * Generates a list of <style> of replacement pseudo class selectors.
   */
  generate() {
    const replacer = (match) => match.replace(/\:/g, ".pseudo-class-")
    let idx, pseudos, rule, stylesheet, _i, _len, _len2, _ref, _ref2
    const _URL_HOST = `${window.location.protocol}//${window.location.host}/`

    /**
     * NOTE: The regex below is prefixed with `(?<!\(.*)` to prevent any of the
     * above pseudo selectors from being matched if they are within parentheses,
     * `()`.
     * The regex will read `(?<!\(.*)(:hover|:enabled|...)` instead of
     * `(:hover|:enabled|...)` (without the prefix applied).
     *
     * Previously, without the prefix, a rule like `button:not(:hover)` will be
     * matched and a `<style>` for `button:not(.pseudo-class-hover)` will be
     * generated. This can potentially cause problems - and we don't want that.
     *
     * Now, with the prefix, the same rule will not be matched and therefore,
     * will not generate a <style> for that rule.
     */
    pseudos = new RegExp(
      "(?<!\\(.*)(\\:" + this.pseudo_selectors.join("|\\:") + ")",
      "g"
    )

    try {
      _ref = document.styleSheets
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        stylesheet = _ref[_i]
        // Check to see if this stylesheet is in the whitelist
        if (
          this.whitelist.length !== 0 &&
          !this.whitelist.includes(stylesheet.href.replace(_URL_HOST, ""))
        ) {
          // skip the loop
          continue
        }

        if (stylesheet.href && stylesheet.href.indexOf(document.domain) >= 0) {
          _ref2 = stylesheet.cssRules
          for (idx = 0, _len2 = _ref2.length; idx < _len2; idx++) {
            rule = _ref2[idx]
            if (
              rule.type === CSSRule.STYLE_RULE &&
              pseudos.test(rule.selectorText)
            ) {
              this.rules.push(rule.cssText.replace(pseudos, replacer))
            }
            pseudos.lastIndex = 0
          }
        }

        if (this.rules.length > 0) {
          // Join up all rules and insert into a single `<style>`
          this.insertRule(this.rules.join(""))
          // Reset rules
          this.rules = []
        }
      }
    } catch (_error) {}
  }

  /**
   * Creates a <style> for a given CSS rule.
   * @param {string} rule CSS rule text to create.
   */
  insertRule(rule) {
    const headEl = document.getElementsByTagName("head")[0]
    const styleEl = document.createElement("style")
    styleEl.setAttribute("type", "text/css")
    styleEl.appendChild(document.createTextNode(rule))
    headEl.appendChild(styleEl)
  }
}

export default KssStateGenerator
