import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_search_input = _resolveComponent("search-input")
  const _component_search_results = _resolveComponent("search-results")

  return (_openBlock(), _createBlock("div", {
    class: "search",
    onKeyup: _cache[2] || (_cache[2] = (...args) => (_ctx.watchKeys && _ctx.watchKeys(...args)))
  }, [
    _createVNode(_component_search_input, {
      onOnInput: _ctx.onSearchInputOnInput,
      ref: "searchInput"
    }, null, 8, ["onOnInput"]),
    _createVNode(_component_search_results, {
      keyword: _ctx.searchTerm,
      items: _ctx.searchResults,
      onOnItemNavigate: _cache[1] || (_cache[1] = (id) => _ctx.$emit('on-item-navigate', id))
    }, null, 8, ["keyword", "items"])
  ], 32))
}

  import SearchInput from "./SearchInput.vue"
  import SearchResults from "./SearchResults.vue"
  import {
    searchReferenceFilter,
    searchReferenceSort,
  } from "../../helpers/search"

  /**
   * Search component props.
   * @typedef {Object} SearchProps
   * @property {SearchReferenceItem[]} data Dataset used for search
   */

  /**
   * Search component data.
   * @typedef {Object} SearchData
   * @property {string} searchTerm Search keyword
   */

  export default {
    name: "search",
    /**
     * @type {SearchProps}
     */
    props: {
      data: {
        type: Array,
        required: true,
      },
    },
    emits: {
      // When search result item link is being navigated to
      "on-item-navigate": (id) => typeof id === "string",
    },
    components: {
      SearchInput,
      SearchResults,
    },
    /**
     * @returns {SearchData}
     */
    data() {
      return {
        searchTerm: "",
      }
    },
    computed: {
      /**
       * Computes the search results based on the search keyword.
       * @returns {SearchReferenceItem[]} Search results
       */
      searchResults() {
        if (this.searchTerm.length < 3 || !this.data.length) return []

        const term = this.searchTerm.toLowerCase()
        return (
          this.data
            // Filter based on occurences in header or description
            .filter(searchReferenceFilter(term))
            // Sort occurences in header to the top as a more relevant match
            .sort(searchReferenceSort(term))
        )
      },
    },
    methods: {
      /**
       * Sets focus on the search input box.
       */
      focusInput() {
        this.$refs.searchInput.focus()
      },
      /**
       * Called when search input value changes.
       * @param {string} value Value in search input field
       */
      onSearchInputOnInput(value) {
        this.searchTerm = value
      },
      /**
       * Watch key strokes for Up/Down Arrow keys to navigate search results.
       * @param {Event} e Event object
       */
      watchKeys(e) {
        if (e.key !== "ArrowDown" && e.key !== "ArrowUp") return
        if (this.searchTerm.length < 3 || !this.searchResults.length) return

        const resultList = document.querySelector(".search-results")

        if (this.searchResults.length && resultList) {
          const links = Array.from(resultList.getElementsByTagName("a"))
          const index = links.indexOf(document.activeElement)

          // Navigating with Down Arrow key
          if (e.key === "ArrowDown") {
            if (index < links.length - 1) links[index + 1].focus()
          }

          // Navigating with Up Arrow key
          if (e.key === "ArrowUp") {
            if (index > 0) links[index - 1].focus()
            else if (index === 0) this.focusInput()
          }
        }
      },
    },
  }
