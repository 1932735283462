module.exports = function (Handlebars) {
  /**
   * Returns a single section, found by its reference
   * @param  {String} reference The reference to search for.
   */
  Handlebars.registerHelper("section", function (reference, options) {
    let section = options.data.root.styleGuide.sections(reference)

    return section.toJSON ? options.fn(section.toJSON()) : options.inverse("")
  })
}
