
          import script, { render } from "./BaseModal.vue?vue&type=script"
          import "./BaseModal.vue?vue&type=style"

          script.render = render

          script.__file = "./BaseModal.vue"
          script.__scopeId = "data-v-9y4s2d"

          export default script
        