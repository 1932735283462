
          import script, { render } from "./SearchInput.vue?vue&type=script"
          import "./SearchInput.vue?vue&type=style"

          script.render = render

          script.__file = "./SearchInput.vue"
          script.__scopeId = "data-v-ujwix4"

          export default script
        