import "tippy.js/dist/tippy.css"
import "tippy.js/animations/scale.css"
import tippy, { delegate } from "tippy.js"
import ClipboardJS from "clipboard"
import { createApp } from "vue"
import TheSearchModal from "./vue/components/TheSearchModal.vue"
import KssStateGenerator from "./internal/behaviors/kss-state-generator"
import KssFullScreen from "./internal/features/kss-fullscreen"
import KssMarkup from "./internal/features/kss-markup"
import KssGrid from "./internal/features/kss-grid"
import LssScrollDirectionIndicator from "./internal/behaviors/lss-scroll-direction-indicator"
import LssScrollSpy from "./internal/behaviors/lss-scrollspy"
// import React from "react"
// import ReactDOM from "react-dom"
// import SearchModal from "./react/components/SearchModal"

/**********************************
 * Style guide behaviors
 *********************************/

// Pseudo class generation
new KssStateGenerator(["styles/main.css"])

// Scroll direction indication
const scrollDirection = new LssScrollDirectionIndicator()

// ScrollSpy
new LssScrollSpy({
  selectorNavItem: ".kss-nav__menu-link",
  selectorSection: ".kss-section",
  activeClass: "is-in-viewport",
})

/**********************************
 * Style guide features
 *********************************/

// Full screen mode
new KssFullScreen({
  idPrefix: "kss-fullscreen-",
  bodyClass: "kss-fullscreen-mode",
  elementClass: "is-fullscreen",
})

// // Toggle grid
new KssGrid({
  bodyClass: "kss-grid-mode",
})

// Toggle all markup wrappers
new KssMarkup({
  bodyClass: "kss-markup-mode",
  detailsClass: "kss-markup",
})

/**********************************
 * Tippy.js
 *********************************/

delegate("#kss-node", {
  target: "[data-tippy-content]",
  touch: ["hold", 500],
  theme: "light",
  animation: "scale",
  // enable to troubleshoot
  // hideOnClick: false,
  // trigger: 'click'
})

/**********************************
 * Clipboard.js
 *********************************/

const clipboardTooltip = (e) => {
  tippy(e.trigger, {
    trigger: "manual",
    content: "Copied!",
    showOnCreate: true,
    onShown: (instance) => setTimeout(() => instance.hide(), 1000),
    onHidden: (instance) => instance.destroy(),
  })
  e.clearSelection()
}

// Markup copy
const clipboardMarkup = new ClipboardJS("[data-clipboard-snippet]", {
  target: (trigger) => trigger.nextElementSibling,
}).on("success", clipboardTooltip)

// Generic copy - copies inner text
const clipboardGeneric = new ClipboardJS("[data-clipboard-copy]", {
  target: (trigger) => trigger,
}).on("success", clipboardTooltip)

/**********************************
 * Search Modal
 *********************************/

// Vue implementation
const vmTheSearchModal = createApp(TheSearchModal).mount("#modal")
const trigger = document.querySelector("#search-box-trigger")

trigger.addEventListener("click", (e) => {
  e.preventDefault()
  vmTheSearchModal.open()
})

// React implementation
// ReactDOM.render(<SearchModal />, document.getElementById("react"))

// #53 Only "keydown" works for Firefox, "keyup" gets suppressed
window.addEventListener("keydown", (e) => {
  // Opens search box
  if (e.key === "/" && !vmTheSearchModal.isActive) {
    // #53 Prevents opening of "quickfind" bar from popping up in Firefox
    e.preventDefault()
    vmTheSearchModal.open()
  }
  // Closes search box
  if (e.key === "Escape" && vmTheSearchModal.isActive) {
    vmTheSearchModal.close()
  }
})

/**********************************
 * Browser Interaction Fixes
 *********************************/

// Fix Android Chrome not following anchors
const isAndroid = /Android/i.test(navigator.userAgent)

if (isAndroid) {
  const rehash = () => {
    const hash = window.location.hash
    window.location.hash = ""
    window.location.hash = hash

    // Hide side bar
    setTimeout(() => {
      scrollDirection.setScrollDown()
    }, 2000)
  }

  // On load
  window.addEventListener("DOMContentLoaded", (event) => {
    if (window.location.hash) {
      setTimeout(rehash, 300)
    }
  })

  // On link clicks
  document.body.addEventListener("click", (e) => {
    // If event target has a "href" attribute defined
    if (e.target.href && e.target.href.indexOf("#") > -1) {
      setTimeout(rehash, 300)
    }
  })
}
